@font-face {
  font-family: "Modeco";
  src: url(ModecoTrial-Regular.otf);
  font-weight: normal;
}

@font-face {
  font-family: "Modeco";
  src: url(ModecoTrial-Bold.otf);
  font-weight: bold;
}

@font-face {
  font-family: "BA";
  src: url(ba.ttf);
  font-weight: normal;
}

@font-face {
  font-family: "BA";
  src: url(bab.ttf);
  font-weight: bold;
}

body {
  margin: 0;
  font-family: "Modeco";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(rgba(241, 236, 236, 0) 10%, #f3f0f0 100%);
}

footer {
  background-color: #2f2b2b;
  font-size: 13px;
  color: #FFF;
  margin-top: 80px;
}

footer a {
  color: #FFF;
}

small {
  opacity: 0.333;
}