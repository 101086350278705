.App {
  text-align: left;
  max-width: 960px;
  margin: 0 auto;
  padding: 30px;
}

.App-logo {
  height: 22vmin;
  pointer-events: none;
  margin-top: 40px;
  opacity: 0.888;
}

.App-home {
  background: #6A2D21 url(ea.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  min-height: 100vh;
  width: 100%;
  border-bottom: 5px solid #2f2b2b;
  background-position: bottom right;
  position: relative;
}

header {
  position: absolute; 
  bottom: 30px; 
}

.App-header {
  max-width: 400px;
  font-size: calc(20px + 2vmin);
  line-height: calc(25px + 2vmin);
  font-family: "Modeco";
  font-weight: bold;
  text-align: left;
  color: #fff;
}

.article {
  font-family: 'BA';
  font-size: 13px;
  line-height: 18px;
  -webkit-column-count: 3;
     -moz-column-count: 3;
          column-count: 3;
}

.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 30px;
}

@media only screen and (max-width: 600px) {
  .article {
    -webkit-column-count: 1;
    -moz-column-count: 1;
        column-count: 1;
  }

  header {
    bottom: 0;
    color: white !important;
  }
}